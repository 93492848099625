<template>
  <v-sheet
    height="100vh"
    class="overflow-hidden"
    style="display: flex; flex-direction: row; justify-content: flex-start"
  >
    <v-navigation-drawer permanent>
      <v-list nav dense>
        <router-link to="/purchase-orders/" class="menu link">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-folder-table-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Purchase Orders</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link to="/purchase-orders/draft-orders" class="menu link">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-folder-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Draft Orders</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link to="/purchase-orders/open-orders" class="menu link">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-folder-open-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Open Orders</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          to="/purchase-orders/partial-delivered-orders"
          class="menu link"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-folder-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Partial Delivered Orders</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link to="/purchase-orders/completed-orders" class="menu link">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-folder-star-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Completed Orders</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link to="/purchase-orders/cancelled-orders" class="menu link">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-folder-remove-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cancelled Orders</v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>
    <v-container>
      <router-view />
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "PurchaseOrders",
};
</script>

<style>
.link {
  text-decoration: none;
}
</style>
